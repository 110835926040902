
.customer-service{
   .title-list{
      position: fixed;
      a{
         &:hover{
            font-weight: bolder;
         }
      }
   }
}