.checkout{
   .form-control{
      display: block;
      width: 100%;
      height: 2rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      appearance: none;
      transition: none;
      border-radius: 0;
      padding: 1.25rem 0.75rem;
   }
   .form-select{
      display: block;
      width: 100%;
      height: 2rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      appearance: none;
      transition: none;
      border-radius: 0;
      padding: 1.20rem 0.5rem;
   }
   .form-floating > .form-control{
      height: calc(3.25rem + 2px);
      line-height: 1.25;
   }
   .form-floating > .form-select {
      height: calc(3.5rem + 2px);
      line-height: 1.25;
   }
}
.image-container-basket-checkout {
   position: relative;
}
.product-thumbnail-quantity {
   position: absolute;
   top: -0.5rem;
   right: -0.5rem;
   width: 1.25rem;
   height: 1.25rem;
   border-radius: 50%;
   background-color: $secondary;
   color: $white;
   font-size: 0.75rem;
   font-weight: 500;
   text-align: center;
   line-height: 1.25rem;

}
.table > :not(caption) > * > * {
   padding: .75rem .5rem;
}