footer{
   color: $white;
   ul,
   li {
      text-decoration: none;
      list-style-type: none;
      padding: 0;
   }
   a{
      color: $white;
      &:hover{
         color: $primary;
      }
   }
}