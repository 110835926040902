
.form-control{
   display: block;
   width: 100%;
   height: 1.5rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: $black;
   background-color: $white;
   background-clip: padding-box;
   border: none;
   border-bottom: 1px solid $black;
   appearance: none;
   transition: none;
   border-radius: 0;
   padding: 1.25rem 0rem;
}
.form-select{
   display: block;
   width: 100%;
   height: 2rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: $black;
   background-color: $white;
   background-clip: padding-box;
   border: none;
   border-bottom: 1px solid $black;
   appearance: none;
   transition: none;
   border-radius: 0;
   padding: 1.25rem 0.5rem;
}

.form-floating > .form-control, .form-floating > .form-select {
   height: calc(3.5rem + 2px);
   line-height: 1.25;
}

textarea{
   min-height: 12rem;
}

// Custom size / color radios

