.btn-primary {
   color: $white;
   background-color: $black;
   border-color: $black;
   border-radius: 0;
   text-transform: uppercase;
   font-weight: 500;
   letter-spacing: 2px;
   padding: 1.25rem;
   &:hover{
      color: $white;
      background-color: $black;
      border-color: $primary;
   }
}
.btn-secondary {
   color: $black;
   background-color: $white;
   border-color: $black;
   border-radius: 0;
   text-transform: uppercase;
   font-weight: 500;
   letter-spacing: 2px;
   padding: 1.25rem;
   &:hover{
      color: $black;
      background-color: $primary;
      border-color: $black;
   }
}
.btn-checkout {
   color: $white;
   background-color: $black;
   border-color: $black;
   border-radius: 0;
   text-transform: uppercase;
   font-weight: 400;
   letter-spacing: 1.1px;
   padding: 1.085rem;
   &:hover{
      color: $white;
      background-color: $black;
      border-color: $primary;
   }
   a{
      color: $white;
   }
}
.btn-small {
   color: $white;
   background-color: $black;
   border-color: $black;
   border-radius: 0;
   text-transform: uppercase;
   font-weight: 400;
   letter-spacing: 1.1px;
   padding: 0.885rem;
   &:hover{
      color: $white;
      background-color: $black;
      border-color: $primary;
   }
   a{
      color: $white;
   }
}
.accordion-button{
   &:not(.collapsed){
      color: $black;
      background-color: transparent;
      box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
      &::after{
         color: $secondary;
      }
   }
   &:focus {
      z-index: 3;
      border-color: $white;
      box-shadow: none;
   }
}
