#header{
   .navbar{
      .dropdown-menu{
         border: none;
         border-radius: 0rem;
      }
      .navbar-expand-lg{
         flex-flow: row nowrap;
         .menu{
            flex-wrap: wrap;
         }
         ul, li{
            list-style-type: none;
            text-decoration: none;
         }
      }
   }
   .form-control {
      display: block;
      width: 100%;
      height: 3.6rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $black;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      appearance: none;
      transition: none;
      border-radius: 0;
      padding: 1.25rem 0.75rem;
   }
   .currencies-login{
      &:hover{
         cursor: pointer;
      }
   }
   .offcanvas-end{
      width: 100%;
      height: 100%;
      max-width: 400px;
      .form-option-label{
         border-color: $secondary;
         color: $secondary;
      }
      .form-control{
         border: none;
         border-bottom: none;
         height: 2rem;
      }
      .minus-button, .plus-button{
         background-color: transparent;
         border-color: transparent;
      }
      .product-delete-button{
         background: none;
         border: none;
      }
   }
}

.search-bar-appear{
   display: none;
}
.search-bar-appear.show{
   display: block;
}
.search-bar-appear-mobile{
   display: none;
}
.search-bar-appear-mobile.show{
   display: block;
}
/*
.dropdown:hover .dropdown-menu {
   display: block;
   margin-top: 0;
}
*/

#header .currencies-login .cart-login-search ul li a svg {
   width: 24px;
   height: 24px;
   fill: #000;
}
.menu-mobile-button{
   position: relative;
   width: 24px;
   height: 22px;
   left: 10%;
   padding: 0;
   .icon-1, .icon-2, .icon-3 {
      position: absolute;
      top: 50%;
      width: 22px;
      height: 1px;
      background-color: black;
      transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
  }
  .icon-1 {
    transform: translateY(-8px);
    animation-delay: 100ms;
  }
  .icon-3 {
    transform: translateY(8px);
    animation-delay: 250ms;
  }
  .hamburger-icon {
    height: 22px;
    width: 22px;
    top: 10%;
    z-index: 1000;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    background: rgba(255,255,255,0.2);
    &:hover {
      transform: scale(1.2);
      box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    }
  }
  .icon-1.a {
    transform: rotate(40deg);
  }
  .icon-3.b {
    transform: rotate(-40deg);
  }
  .icon-2.c {
    opacity: 0;
  }
  .clear {
    clear: both;
  }
  
}

 
/* menu opened */

.nav-content-mobile{
   position: fixed;
   height: 100%;
   width: 100%;
   background: $white;
   z-index: 999;
   display: none;
   &.show{
      display: block;
   }
   .navlinks-menu{
      list-style-type: none;
      position: relative;
      .navlink-mobile{
         text-transform: uppercase;
      }
   }
}
