$white: #fff;
$black: #000;
$grey: #f0f0f0;
$primary: #fecea8;
$secondary: #A12533;

.bg-primary{
   background-color: $primary !important;
}
.bg-secondary{
   background-color: $secondary !important;
}
.bg-grey{
   background-color: $grey;
}
.primary-color{
   color: $primary;
}
.secondary-color{
   color: $secondary;
}
.text-white{
   color: $white;
}

a{
   color: $black;
   background-color: transparent;
   text-decoration: none;
   &:hover{
      opacity: 70%;
      color: $secondary;
   }
}

